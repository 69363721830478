import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/modules/Layout"
import BodyBlocks from "../../components/technical/BodyBlocks"
import Seo from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Seo"

const IndexPageEN = ({ data }) => {
  const { story } = data
  const language = story.fields.language

  const content = JSON.parse(story.content)

  return (
    <Layout language={language}>
      <Seo title={content.headline} content={content} name={story.name} />
      <BodyBlocks body={content.body} />
    </Layout>
  )
}

export const data = graphql`
  {
    story: storyblokEntry(full_slug: { eq: "en/" }) {
      name
      content
      full_slug
      fields {
        language
      }
    }
  }
`

export default IndexPageEN
